import styles from "../styles/dialogue.module.css";
import React, { useState, useEffect } from 'react';
import Chat from "../components/Chat"
import Header from '../components/Header';

const ChatWindow = () => { 

  const [selectedOption, setSelectedOption] = useState('Business growth (CEO)');

  useEffect(() => {
  }, []);

  return (
    <div className={styles.newDialog}>
      <Header selectedOption={selectedOption} setSelectedOption={setSelectedOption} />
      <Chat selectedOption={selectedOption} />     
    </div>
  );
};

export default ChatWindow;
