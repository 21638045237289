import React, { useState } from 'react';
import styles from '../styles/header.module.css';
import logo from '../images/main-logo.svg';
import downArraow from '../images/dialogue/down arrow.svg';
import tickIcon from '../images/dialogue/tick.svg';

const Header = ({ selectedOption, setSelectedOption }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  return (
    <header className={styles.header}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <div className={styles.dropdownContainer} onClick={() => setIsDropdownVisible(!isDropdownVisible)}>
        I'm Accountable for <span className={styles.selectedOption}>{selectedOption}</span>
        <img
          className={styles.downArrow}
          title='I am accountable for ?'
          src={downArraow}
          alt="Down Arrow"
        />
        
        {isDropdownVisible && (
          <div className={styles.dropdownMenu}>
            {['Business growth (CEO)', 'Customer Experience', 'Finance', 'Marketing and sales', 'Operations'].map(option => (
              <div
                key={option}
                onClick={() => { setSelectedOption(option); setIsDropdownVisible(false); }}
                className={styles.dropdownItem}
              >
                {option} {selectedOption === option && <img src={tickIcon} alt="selected" className={styles.tickIcon} />}
              </div>
            ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;