// src/components/SuggestedQuestions.js
import React, { useEffect, useRef, useState } from 'react';
import styles from '../styles/suggestedQuestions.module.css';
import lightGif from '../images/dialogue/idea.gif';

const SuggestedQuestions = ({ suggestions, onClickSuggestion }) => {
  if (!suggestions || suggestions.length === 0) return null;

  return (
    <div className={styles.suggestedQuestionsContainer}>
      <h4 style={{ color: '#fff', margin: '8px 0px' }}>
        Suggested Questions 
      </h4>
      <div className={styles.suggestedQuestionsDiv}>
        {suggestions
          .filter(suggestion => suggestion && suggestion.trim()) // Filter out empty or whitespace-only suggestions
          .map((suggestion, index) => (
            <SuggestionBox 
              key={index} 
              suggestion={suggestion} 
              onClickSuggestion={onClickSuggestion} 
            />
        ))}
      </div>
    </div>
  );
};

const SuggestionBox = ({ suggestion, onClickSuggestion }) => {
  const boxRef = useRef(null);
  const [fontSize, setFontSize] = useState('14px');

  useEffect(() => {
    const adjustFontSize = () => {
      const box = boxRef.current;
      if (box) {
        let newFontSize = 14; // start with a base font size
        while (box.scrollHeight > box.clientHeight && newFontSize > 10) {
          newFontSize -= 1;
          box.style.fontSize = `${newFontSize}px`;
        }
        setFontSize(`${newFontSize}px`);
      }
    };

    adjustFontSize();
  }, [suggestion]);

  return (
    <div 
      className={styles.suggestionBox} 
      onClick={() => onClickSuggestion(suggestion)}
      ref={boxRef}
      style={{ fontSize }}
    >
      <div className={styles.suggestedQuestionsContainerIdeaIcon}>
        <img src={lightGif} style={{ height: '42px', margin: '0px 5px 0px 0px', alignItems: 'flex-start' }} />
      </div>
      <div style={{ color: '#fff' }}>
        {suggestion}  
      </div>              
    </div>
  );
};

export default SuggestedQuestions;