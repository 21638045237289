import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import styles from '../styles/home.module.css';
import dialogueStyles from '../styles/dialogue.module.css';
import volume from '../images/dialogue/speaker.svg';
import mute from '../images/dialogue/non-speaker.svg';
import volumeDisabled from '../images/dialogue/speaker-disable.png';
import storyIcon from '../images/dialogue/storymetrix chat bird.svg';
import userIcon from '../images/dialogue/user profile.svg';
import tickIcon from '../images/dialogue/tick.svg';
import loadingGif from '../images/loader2.webp';
import SpeechRecognitionComponent from '../components/SpeechRecognitionComponent';
import SuggestedQuestions from '../components/SuggestedQuestions';
import HighchartsComponent from '../components/HighchartsComponent';
import ThoughtProvokingQuestions from '../components/ThoughtProvokingQuestions';
import DOMPurify from 'dompurify';
import { generatePlotlyConfig } from '../utils/generatePlotlyConfig';
import copyIcon from '../images/dialogue/copy.svg';
import refreshIcon from '../images/dialogue/refresh.svg';
import downvoteIcon from '../images/dialogue/thumbs.svg';
import config from './config';

const Chat = ({ selectedOption }) => {
  const [messages, setMessages] = useState([{ sender: 'bot', content: 'Hello, how can I help you?', sqlQuery: '', firstMessage: 'yes' }]);
  const [newMessage, setNewMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isVolumeEnabled, setIsVolumeEnabled] = useState(true);
  const [latestResponse, setLatestResponse] = useState('');
  const [audioPath, setAudioPath] = useState('');
  const [defaultSuggestions, setDefaultSuggestions] = useState([]);
  const [thoughtProvokingQuestion, setThoughtProvokingQuestion] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [showQuestions, setShowQuestions] = useState(true);
  const [clickCounts, setClickCounts] = useState({});
  const chatEndRef = useRef(null);
  const audioRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [loadingText, setLoadingText] = useState('Please Wait');
  const loadingTexts = ['Querying...', 'Fetching...', 'Analyzing...', 'Generating...'];
  const location = useLocation();
  const [activeButtons, setActiveButtons] = useState({});
  const apiUrl = config.apiUrl;
  
  const getValueFlagFromURL = () => {
    const params = new URLSearchParams(location.search);
    return params.get('voice') || 'false';
  };

  useEffect(() => {

    let suggestions;
    switch (selectedOption) {
      case 'Business growth (CEO)':
        suggestions = [
          'How did NPS change over time?',
          'How did election years affect cash flows?',
          'How does weather impact shipping costs?'
        ];
        break;
      case 'Customer Experience':
        suggestions = [
          'What is the trend of Net Promoter Score over the years from 2013 to 2022?',
          'How does ticket volume vary by region?',
          'Are there any patterns in ticket submissions?'
        ];
        break;
      case 'Finance':
        suggestions = [
          'Compare cashflows from the last 6 months of 2023',
          'Can you provide a summary of sales by quarter?',
          'Identify any significant fluctuations in monthly cash flow trends over the last 2 years and explain why they occurred'
        ];
        break;
      case 'Marketing and sales':
        suggestions = [
          'How do monthly sales during promotional periods compare from 2017 to 2020?',
          'How do sales vary across different regions each month from 2017 to 2020?',
          'What is the NPS score from 2021 to 2023'
        ];
        break;
      case 'Operations':
        suggestions = [
          'What are the most common issues reported by customers?',
          'Are there any recurring issues affecting order fulfillment or delays?',
          'Which marketing campaigns have generated the highest revenue or customer engagement?'
        ];
        break;
      default:
        suggestions = [
          'What time of year do we see the highest revenue and profit spikes?',
          'What are the yearly sales trends by region from 2017 to 2020?',
          'How did the NPS change over time?'
        ];
        break;
    }
    setDefaultSuggestions(suggestions);
  }, [selectedOption]);

  useEffect(() => {
    if (!isLoading) return;

    const changeLoadingText = async () => {
      for (let i = 0; i < loadingTexts.length; i++) {
        await new Promise(resolve => setTimeout(resolve, 2000));
        setLoadingText(loadingTexts[i]);
      }
    };

    setLoadingText('Please Wait'); // Reset to initial state
    changeLoadingText();
  }, [isLoading]);

  useEffect(() => {
    const valueFlag = getValueFlagFromURL();
    setIsVolumeEnabled(valueFlag === 'true');
  }, [location.search]);

  const fetchBotResponse = async (message) => {
    try {
      const company = "codewave";
      const chartType = "";
      const valueFlag = getValueFlagFromURL();
      const messageWithPersona = message;
      const url = apiUrl+`/chat/?question=${messageWithPersona}&graph_prompt=${chartType}&company_name=${company}&value_flag=${valueFlag}&user_persona=${selectedOption}`;
      const response = await axios.get(url, {});
      return response.data.data || response.data;
    } catch (error) {
      console.error('Error fetching bot response:', error);
      return { answer: 'Error: Unable to get response from bot.', suggestions: [], thoughtprovoking: '' };
    }
  };

  // const fetchBotResponse = async (message) => {
  //   try {
  //     const response = await fetch('/line.json');
  //     const jsonData = await response.json();
  
  //     // Assuming your JSON structure has a specific way to get the response, modify accordingly
  //     // const botResponse = jsonData.find(item => item.question === message);
  
  //     if (jsonData) {
  //       return jsonData.data;
  //     } else {
  //       return { answer: 'No matching response found.', suggestions: [], thoughtprovoking: '' };
  //     }
  //   } catch (error) {
  //     console.error('Error fetching bot response:', error);
  //     return { answer: 'Error: Unable to get response from bot.', suggestions: [], thoughtprovoking: '' };
  //   }
  // };
  


  const sendMessage = async (message) => {
    if (message.trim() === '') return;

    const userMessage = { sender: 'user', content: message, sqlQuery: '', firstMessage: 'no' };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setNewMessage('');
    setIsLoading(true);

    const botResponse1 = await fetchBotResponse(message);
    const botResponse = botResponse1.data || botResponse1;
    const botMessage = {
      sender: 'bot',
      content: botResponse?.summary || botResponse?.answer,
      copyContent: "summary - "+botResponse1?.summary_actions?.summary_text+" actions - "+botResponse1?.summary_actions?.actions_text,
      actions: botResponse?.actions,
      sqlQuery: botResponse?.sql || '',
      firstMessage: 'no'
    };
    
    if (botResponse?.table?.length > 1) {
      Object.assign(botMessage, {
        graphTitle: botResponse?.graph_head,
        graphType: botResponse?.graph_type,
        graph: botResponse?.table,
        graphDataToPlot: botResponse?.highchart_code,
      });
    }
    
    setMessages(prevMessages => [...prevMessages, botMessage]);
    setLatestResponse(botResponse?.answer);
    setAudioPath(botResponse?.audio_path);
    setDefaultSuggestions(botResponse?.related_questions || defaultSuggestions);
    setThoughtProvokingQuestion(botResponse?.actions || '');
    setIsLoading(false);
    setShowSuggestions(true);
    setShowQuestions(true);
    


    if (isVolumeEnabled && botResponse?.audio_path) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
      }
      audioRef.current = new Audio(botResponse.audio_path);
      audioRef.current.play();
    }
  };

  const handleTranscript = (transcript) => {
    setNewMessage(transcript);
    setTimeout(() => {
      sendMessage(transcript);
      setNewMessage('');
    }, 1000);
  };

  const handleSuggestionClick = (suggestion) => {
    if(isLoading == false){
      setNewMessage(suggestion);
      sendMessage(suggestion);
    }
  };

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isLoading]);

  const handleEnterKeyPress = (e) => {
    if (e.key === 'Enter' && isLoading==false) {
      sendMessage(newMessage);
    }
  };

  const toggleVolume = () => {
    setIsVolumeEnabled((prev) => {
      const newVolumeState = !prev;
      if (audioRef.current) {
        if (newVolumeState) {
          audioRef.current.play();
        } else {
          audioRef.current.pause();
        }
      }
      return newVolumeState;
    });
  };

  const handleStoryIconClick = (index) => {
    setClickCounts((prevClickCounts) => {
      const newClickCounts = { ...prevClickCounts, [index]: (prevClickCounts[index] || 0) + 1 };
      setTimeout(() => {
        newClickCounts[index] = 0; // Reset click count for this message after 10 seconds
        setClickCounts(newClickCounts);
      }, 10000);
      return newClickCounts;
    });
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Text copied to clipboard');
      console.log(text);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  };
  const handleButtonClick = (buttonType, index) => {
    setActiveButtons(prevActiveButtons => ({
      ...prevActiveButtons,
      [buttonType]: true,
  }));

    if (buttonType === 'copy') {
      copyToClipboard(messages[index].copyContent);
    } else if (buttonType === 'refresh') {
      sendMessage(messages[index-1]?.content);
    } else if (buttonType === 'downvote') {
      console.log('Downvote clicked');
    }
    setTimeout(() => {
      setActiveButtons(prevActiveButtons => ({
        ...prevActiveButtons,
        [buttonType]: false,
      }));
    }, 2000);    
  };

  return (
    <div className={dialogueStyles.chatContainerWrapper}>
      <div className={dialogueStyles.chatWrapper}>
        {messages.map((message, index) => (
          <div key={index} className={message.sender === 'bot' ? styles.chatMessageBotContainer : styles.chatMessageUserContainer}>
            <div className={message.sender === 'bot' ? styles.chatMessageContainerDivBot : styles.chatMessageContainerDivUser}>
              <div className={message.sender === 'bot' ? styles.chatMessageContainerImageBot : styles.chatMessageContainerImageUser}>
                <img
                  src={message.sender === 'bot' ? storyIcon : userIcon}
                  alt={message.sender}
                  onClick={message.sender === 'bot' ? () => handleStoryIconClick(index) : undefined}
                  style={{ height: '24px', margin: '0px 5px 10px', alignItems: 'flex-start' }}
                />
              </div>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }} />
              {clickCounts[index] === 2 && message.sender === 'bot' && message.sqlQuery && (
                <div style={{ marginTop: '10px', fontStyle: 'italic', color: '#555' }}>
                  SQL Query: {message.sqlQuery}
                </div>
              )}
              {message?.graph && (
                <div style={{ position: 'relative', top: '10px', marginBottom: '10px', border: '1px solid #BEEF9E', borderRadius: '15px' }}>
                  <HighchartsComponent chartType={message.graphType} chartTitle={message.graphTitle} data={message.graph} dataToPlot={message.graphDataToPlot} />
                  {(message.sender === 'bot' && message.firstMessage === 'no') &&(
                <div className={styles.messageOptions}>
                  <div onClick={() => handleButtonClick('copy', index)}>
                    
                    <img 
                      src={activeButtons.copy ? tickIcon : copyIcon} 
                      alt="Copy" 
                      className={styles.optionIcon} 
                      
                    />
                    COPY
                  </div>
                  <div onClick={() => handleButtonClick('refresh', index)}>
                    
                    <img 
                      src={activeButtons.refresh ? tickIcon : refreshIcon} 
                      alt="Refresh" 
                      className={styles.optionIcon} 
                      
                    />
                    REGENERATE
                  </div>
                  <div onClick={() => handleButtonClick('downvote', index)}>
                    
                    <img 
                      src={activeButtons.downvote ? tickIcon : downvoteIcon} 
                      alt="Downvote" 
                      className={styles.optionIcon} 
                      title="click to downvote"
                      
                    />
                    DOWNVOTE
                  </div>
                </div>
              )}              
                </div>
              )}
              

            </div>
          </div>
        ))}
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <div className={styles.chatMessageContainerLoader}>
              <img src={storyIcon} alt="bot" style={{ height: '24px', marginRight: '10px' }} />
              <div className={styles.chatMessageContainerLoaderWaitDiv}>
                <div>
                  <img src={loadingGif} alt="loading" style={{ height: '24px' }} />
                </div>
                <span className={styles.chatMessageContainerLoaderWaitSpan}>{loadingText}</span>
              </div>
            </div>
          </div>
        )}
        <div ref={chatEndRef}></div>
        <div className={styles.chatMessageInputWrapper}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleEnterKeyPress}
          placeholder="Ask anything, What can i do for you?"
          className={dialogueStyles.chatMessageInputWrapper}
        />
        <SpeechRecognitionComponent onTranscript={handleTranscript} />
        {getValueFlagFromURL() === 'true' && (
          <img
            style={{ height: '25px', cursor: 'pointer', borderLeft: '1px solid #fff', padding: '0px 10px' }}
            className={styles.homeFill2Icon}
            src={isVolumeEnabled ? volume : mute}
            onClick={toggleVolume}
          />
        )}
        {getValueFlagFromURL() === 'false' && (
          <img
            style={{ height: '25px', cursor: 'pointer', borderLeft: '1px solid #fff', padding: '0px 10px', cursor: 'not-allowed' }}
            className={styles.homeFill2Icon}
            title="disabled volume"
            alt="disabled volume"
            src={volumeDisabled}
          />
        )}
      </div>        
      </div>
      <div className={dialogueStyles.rightSidebar}>
        {showSuggestions && <SuggestedQuestions suggestions={defaultSuggestions} onClickSuggestion={handleSuggestionClick} />}
        {showQuestions && <ThoughtProvokingQuestions question={thoughtProvokingQuestion} />}
      </div>
    </div>
  );
};

export default Chat;