import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Heatmap from 'highcharts/modules/heatmap';
import HighchartsMore from 'highcharts/highcharts-more'; 

Heatmap(Highcharts);
HighchartsMore(Highcharts);

const HighchartsComponent = ({ chartTypeOuter, chartTitle, data, dataToPlot }) => {
  const chartRef = useRef(null);
  const [chartOptions, setChartOptions] = useState({});
  const chartType = chartTypeOuter || dataToPlot?.chart?.type;

  useEffect(() => {
    if (data && data.length > 0) {

      // Dynamically fetch all keys from the first data item
      const keys = Object.keys(data[0]);
      const xKey = keys[0]; // First key for x-axis
      const yKeys = keys.slice(1); // All other keys for y-axis
      
      // console.log(keys);        
      // console.log(xKey);
      // console.log(yKeys);

      // Extract x values from data
      const xValues = data.map(item => item[xKey]);

      let seriesData = [];

      // Function to parse and format numeric values
      const parseValue = value => {
        if (typeof value === 'string') {
          value = value.replace(/,/g, ''); // Remove commas if any
        }
        if (!isNaN(value) && value != null && value !== '') {
          return parseFloat(value);
        }
        return value; // Return as is if not a number
      };

      // Function to format large numbers
      const formatNumber = value => {
        if (typeof value === 'number') {
          if (value >= 1e9) {
            return (value / 1e9).toFixed(1) + 'B';
          } else if (value >= 1e6) {
            return (value / 1e6).toFixed(1) + 'M';
          } else if (value >= 1e3) {
            return (value / 1e3).toFixed(1) + 'k';
          } else {
            return value.toFixed(2);
          }
        }
        return value; // Return as is if not a number
      };
      // console.log(seriesData);
    seriesData = dataToPlot?.series;

      const options = {
        chart: {
          backgroundColor: 'transparent',
          plotBackgroundColor: 'transparent',
          type: chartType === 'pie' ? 'pie' : chartType,
          height: 350,
        },
        title: {
          text: chartTitle,
          style: { color: "#ffffff", fontFamily: 'var(--font-montserrat)' }
        },
        xAxis: {
        //   categories: chartType === 'scatter' || chartType === 'heatmap' ? null : xValues,
          categories: dataToPlot?.xAxis?.categories,
          title: {
            text: xKey,
            style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' }
          },
          labels: {
            style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' }
          },
          gridLineWidth: 0, // Hide x-axis grid lines
        },
        yAxis: {
          title: {
            text: dataToPlot?.highchart_code?.yAxis?.title?.text,
            style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' }
          },
          labels: {
            style: { color: '#ffffff', fontFamily: 'var(--font-montserrat)' },
            formatter: function() {
              return formatNumber(this.value);
            }
          },
          gridLineWidth: 0, // Hide y-axis grid lines
        },
        colorAxis: chartType === 'heatmap' ? {
          min: 0,
          stops: [
            [0, '#3060cf'],
            [0.5, '#fffbbc'],
            [1, '#c4463a']
          ]
        } : undefined,
        series: seriesData,
        credits: {
          enabled: false
        },
        legend: {
          itemStyle: {
            color: '#fff',
            fontFamily: 'var(--font-montserrat)'
          }
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              color: '#fff',
              style: { fontFamily: 'var(--font-montserrat)' },
              formatter: function() {
                return formatNumber(this.y);
              }
            },
            connectNulls: true, // Connect points if null values are present
            marker: {
              enabled: true
            }
          },
          scatter: {
            marker: {
              radius: 5,
              symbol: 'circle'
            }
          },
          heatmap: {
            borderWidth: 1,
            dataLabels: {
              enabled: true,
              color: '#fff',
              style: { fontFamily: 'var(--font-montserrat)' },
              formatter: function() {
                return formatNumber(this.point.value);
              }
            }
          }
        },
        tooltip: {
          style: { fontFamily: 'var(--font-montserrat)' },
          formatter: function() {
            if (chartType === 'pie') {
              return `<b>${this.point.name}</b>: ${formatNumber(this.y)}`;
            } else if (chartType === 'scatter') {
              return `<b>${this.series.name}</b><br/>${xKey}: ${formatNumber(this.x)}<br/>${yKeys.join(', ')}: ${formatNumber(this.y)}`;
            } else if (chartType === 'heatmap') {
              return `<b>${this.series.name}</b><br/>${xKey}: ${formatNumber(this.point.x)}<br/>${yKeys[0]}: ${formatNumber(this.point.y)}<br/>${yKeys[1]}: ${formatNumber(this.point.value)}`;
            } else {
              return `<b>${this.series.name}</b><br/>${xKey}: ${formatNumber(this.key)}<br/>${yKeys.join(', ')}: ${formatNumber(this.y)}`;
            }
          }
        }
      };

      setChartOptions(options);
    }
  }, [chartType, data, chartTitle, dataToPlot]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} ref={chartRef} />;
};

export default HighchartsComponent;