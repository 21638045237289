import { Routes, Route } from 'react-router-dom';
import React, { Component } from 'react'; 
import { Helmet } from "react-helmet";
import "./App.css";
import "../src/styles/global.css";
import ChatWindow from './pages/chatWindow';

function App() {
  return (    
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Storymetrics</title>
          <link rel="canonical" href="www.codewave.com" />
        </Helmet>
        <Routes>
          <Route exact path="/" element={<ChatWindow />}></Route>
        </Routes>       
      </div>
  );
}

export default App;